<template>
  <v-dialog :value="dialog" persistent width="600">
    <v-card>
      <v-card-title class="text-h5 text-center"> Add New Invoice </v-card-title>

      <div class="px-5 my-5">
        <v-row class="d-flex align-center justify-space-between">
          <v-col cols="12" sm="6">
            <v-select
              label="Invoice type (Required)"
              v-model="invoice.invoice_type"
              :items="invoiceTypes"
              item-text="label"
              item-value="value"
              class="mt-2"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              label="Invoice ID (Required)"
              :value="invoice.visit_id"
              @input="(val) => (invoice.visit_id = Number(val))"
              outlined
              dense
              type="number"
              hide-details="auto"
              class="mb-1 mt-1"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <app-date-picker
              :range="false"
              v-model="invoice.posting_date"
              label="Invoice Date (Required)"
              name="posting-date"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              outlined
              dense
              label="Amount (Required)"
              hide-details="auto"
              class="mb-1 mt-1"
              type="number"
              :value="invoice.total_charge"
              @blur="setInvoiceAmounts"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <app-date-picker
              :range="false"
              v-model="invoice.date_of_service"
              :label="
                invoice.invoice_type === 'prePay'
                  ? 'Expiration Date (Required)'
                  : 'Date of Service (Required)'
              "
              name="date-of-service"
            />
          </v-col>

          <v-col cols="12">
            <v-textarea
              outlined
              dense
              label="Description (Required)"
              hide-details="auto"
              class="mb-1 mt-1"
              v-model="invoice.procedure_description"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-btn
          @click="toggleShowMore"
          text
          color="primary"
          class="mt-5 mb-2 px-0"
        >
          {{ showMore === 0 ? 'Fewer Options' : 'More Options' }}
        </v-btn>
        <v-expansion-panels flat accordion v-model="showMore">
          <v-expansion-panel>
            <v-expansion-panel-content class="more-options">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Service Detail ID"
                    hide-details="auto"
                    class="mb-1 mt-1"
                    v-model="invoice.service_detail_id"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Procedure Code"
                    hide-details="auto"
                    class="mb-1 mt-1"
                    v-model="invoice.procedure_code"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Patient Paid"
                    hide-details="auto"
                    class="mb-1 mt-1"
                    type="number"
                    :value="invoice.patient_paid"
                    @input="(val) => (invoice.patient_paid = Number(val))"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Insurance Paid"
                    hide-details="auto"
                    class="mb-1 mt-1"
                    type="number"
                    :value="invoice.insurance_paid"
                    @input="(val) => (invoice.insurance_paid = Number(val))"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Insurance Balance"
                    hide-details="auto"
                    class="mb-1 mt-1"
                    type="number"
                    :value="invoice.insurance_balance"
                    @input="(val) => (invoice.insurance_balance = Number(val))"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Adjusted Amount"
                    hide-details="auto"
                    class="mb-1 mt-1"
                    type="number"
                    :value="invoice.adjusted_amount"
                    @input="(val) => (invoice.adjusted_amount = Number(val))"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Insurance Adjusted Amount"
                    hide-details="auto"
                    class="mb-1 mt-1"
                    type="number"
                    :value="invoice.insurance_adj_amount"
                    @input="
                      (val) => (invoice.insurance_adj_amount = Number(val))
                    "
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Write off Amount"
                    hide-details="auto"
                    class="mb-1 mt-1"
                    type="number"
                    :value="invoice.write_off_amount"
                    @input="(val) => (invoice.write_off_amount = Number(val))"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <app-date-picker
                    :range="false"
                    v-model="invoice.last_statement_date"
                    label="Last Statement Date"
                    name="last-statement-date"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Last Statement Amount"
                    hide-details="auto"
                    class="mb-1 mt-1"
                    type="number"
                    :value="invoice.last_statement_amount"
                    @input="
                      (val) => (invoice.last_statement_amount = Number(val))
                    "
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    outlined
                    dense
                    label="Location ID"
                    hide-details="auto"
                    class="mb-1 mt-1"
                    v-model="invoice.location_id_pk"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')" :disabled="createInvoiceLoading">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="saveInvoice"
          :disabled="createInvoiceLoading"
          :loading="createInvoiceLoading"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import AppDatePicker from '@/components/app-date-picker/app-date-picker.vue';

const { mapGetters: invoiceGetter, mapActions: invoiceActions } =
  createNamespacedHelpers('invoices');
const { mapGetters: patientGetters } = createNamespacedHelpers('patient');

export default {
  name: 'CreateInvoice',
  components: {
    AppDatePicker,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: '',
      showMore: -1,
      invoiceTypes: [
        { label: 'Pay', value: 'postPay' },
        { label: 'Pre-Pay', value: 'prePay' },
      ],
      invoice: {
        invoice_type: 'postPay',
        visit_id: '',
        charge_id: '',
        posting_date: null,
        procedure_description: '',
        total_charge: '',
        patient_balance: null,
        service_detail_id: null,
        procedure_code: '',
        date_of_service: null,
        patient_paid: null,
        insurance_paid: null,
        insurance_balance: null,
        adjusted_amount: null,
        insurance_adj_amount: null,
        write_off_amount: null,
        last_statement_date: null,
        last_statement_amount: null,
        location_id_pk: null,
      },
    };
  },
  computed: {
    ...patientGetters({
      patient: 'PATIENT',
    }),
    ...invoiceGetter({
      createInvoiceLoading: 'CREATE_INVOICE_LOADING',
    }),
  },
  methods: {
    ...invoiceActions(['createInvoice']),
    setInvoiceAmounts(event) {
      const value = Number(event.target.value).toFixed(2);
      this.invoice.total_charge = value;
      this.invoice.patient_balance = value;
    },
    toggleShowMore() {
      if (this.showMore === 0) {
        this.showMore = -1;
      } else {
        this.showMore = 0;
      }
    },
    async saveInvoice() {
      const resp = await this.createInvoice({
        data: {
          ...this.invoice,
          patient_id: this.patient.patient_id,
          pid: this.patient.id,
          statusIsPaid: false,
          auto_charge_id: true,
        },
      });

      if (resp?.success) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px !important;
  width: 100% !important;
}

::v-deep .mx-datepicker {
  width: 100% !important;
}
</style>
